import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "./bannerModal.css";
import { ReactNode } from "react";
import closeBtn from "../../assets/closeBtn.png";
import { useMixpanelPublic } from "../../helpers/mixpanel";
import classNames from "classnames";

type BannerModalProps = {
  onExit: React.Dispatch<React.SetStateAction<boolean>>;
  showBannerModal?: boolean;
  title: string | ReactNode;
  isLinkTitle?: boolean;
  description: string | ReactNode;
  textButton?: string;
  textSecondaryButton?: string;
  linkText?: string;
  link?: string;
  footerText?: string | ReactNode;
  butttonType?: string;
  className?: string;
  linkText_2?: string;
  onClickMainCTABannerModal?: () => void;
  onClickSecondaryBannerModal?: () => void;
};

function BannerModal({
  className,
  title,
  isLinkTitle,
  description,
  textButton,
  textSecondaryButton,
  linkText,
  showBannerModal,
  onExit,
  onClickMainCTABannerModal,
  onClickSecondaryBannerModal,
  footerText,
  link,
  butttonType,
}: BannerModalProps) {
  const mixpanelPublic = useMixpanelPublic();

  const onClickedLink = () => {
    mixpanelPublic("Clicked_NewYear_LRPage_PopUp");
  };

  const onClose = () => {
    onExit(!showBannerModal);
  };

  const isOneButton =
    textButton &&
    onClickMainCTABannerModal &&
    !textSecondaryButton &&
    !onClickSecondaryBannerModal;

  return (
    <div className="container_banner_modal_color" onClick={onClose}>
      <div className={classNames("container_banner_modal", className)}>
        <div
          className="banner_modal__content"
          onClick={(event) => event.stopPropagation()}
        >
          {isLinkTitle && (
            <div
              className={classNames(
                "close_button_banner_modal",
                "close_button_banner_modal_line",
              )}
            >
              <img src={closeBtn} alt="close button" onClick={onClose} />
            </div>
          )}
          <div className="close_button_container">
            {!isLinkTitle && (
              <div className="close_button_banner_modal">
                <img src={closeBtn} alt="close button" onClick={onClose} />
              </div>
            )}
          </div>
          <div className="banner_box_title">{title}</div>

          {description}

          {!butttonType && (
            <div className="banner_button_group">
              {textButton && (
                <Button
                  className="banner_modal_button"
                  onClick={onClickMainCTABannerModal}
                >
                  {textButton}
                </Button>
              )}
              {linkText && link && (
                <Link
                  className="banner_modal_link"
                  target="_blank"
                  to={link}
                  onClick={onClickedLink}
                >
                  {linkText}
                </Link>
              )}
              {onClickSecondaryBannerModal && textSecondaryButton && (
                <Button
                  style={{ padding: "0 0 0 0", color: "var(--primary-color)" }}
                  onClick={onClickSecondaryBannerModal}
                >
                  <p className="banner_modal_link">{textSecondaryButton}</p>
                </Button>
              )}
            </div>
          )}

          {(butttonType === "laurelBtn" ||
            butttonType === "feedbackBtn" ||
            butttonType === "lubinBtn" ||
            butttonType === "thanksBtn") &&
            isOneButton && (
              <div className="continue_btn_box">
                <Button
                  className="continue_btn"
                  style={{ backgroundColor: "var(--primary-color)" }}
                  onClick={onClickMainCTABannerModal}
                >
                  {textButton}
                </Button>
              </div>
            )}

          {butttonType && !isOneButton && (
            <div className="btn_box_modal">
              <Button
                className="secondaryBtn"
                onClick={onClickSecondaryBannerModal}
              >
                {textSecondaryButton}
              </Button>
              <Button
                className={
                  butttonType === "InterestBtn" ? "interest_mainBtn" : "mainBtn"
                }
                onClick={onClickMainCTABannerModal}
              >
                {textButton}
              </Button>
            </div>
          )}

          {footerText}
        </div>
      </div>
    </div>
  );
}

export default BannerModal;

import sliderIcon from "../assets/slider_icon.svg";
import { createTheme } from "@mui/material";

export const darkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 744,
      md: 1025,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#39848A",
    },
    secondary: {
      main: "#EB853F",
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "#303030",
          fontSize: "12px",
          padding: "8px",
          fontFamily: `"Open Sans", sans-serif`,
          textAlign: "center",
          width: "257px",
        },
        arrow: {
          color: "#303030",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          lineHeight: "24px",
          padding: "16px 32px",
          textTransform: "capitalize",
          fontWeight: 700,
          borderRadius: "8px",
          color: "#EEEEEE",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "72px !important",
          padding: "0px !important",
          borderBottom: "1px solid  #707070",
          background: "#000",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "20px !important",
          paddingRight: "20px !important",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: "1px",
        },
        track: {
          color: "#EEE",
          transform: "inherit",
          backgroundColor: "#EEE",
        },
        rail: {
          color: "#EEE",
          opacity: 1,
          transform: "inherit",
          backgroundColor: "#EEE",
        },
        mark: {
          color: "#EEE",
          width: 0,
        },
        markLabel: {
          top: "2px",
          fontSize: "16px",
          color: "#EEE",
          '&[data-index="0"]': {
            left: "-7%!important",
          },
          '&[data-index="1"]': {
            left: "107%!important",
          },
        },
        markLabelActive: {
          background: "red",
        },
        valueLabel: {
          background: "red",
          fontSize: "16px",
          top: "54px",
          backgroundColor: "transparent",
        },
        thumb: {
          width: "24px",
          height: "24px",
          backgroundImage: `url(${sliderIcon})`,
          backgroundSize: "28px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "-2px -1px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: `"Open Sans", sans-serif`,
        },
        h1: {
          fontSize: "40px",
          lineHeight: "48px",
          fontWeight: "bold",
        },
        h2: {
          fontSize: "32px",
          lineHeight: "40px",
          fontWeight: "bold",
        },
        h3: {
          fontSize: "28px",
          lineHeight: "36px",
          fontWeight: "bold",
        },
        h4: {
          fontSize: "24px",
          lineHeight: "32px",
          fontWeight: "bold",
        },
        h5: {
          fontSize: "20px",
          lineHeight: "24px",
          fontWeight: "bold",
        },
        h6: {
          fontSize: "18px",
          lineHeight: "20px",
          fontWeight: "bold",
        },
        body1: {
          fontSize: "16px",
          lineHeight: "14px",
        },
        body2: {
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          background: "#303030",
          color: "#EEE",
        },
        input: {
          "&::placeholder": {
            opacity: 1,
            color: "#707070",
          },
        },
      },
    },
  },
});

import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { Toaster } from "react-hot-toast";
import PrivateRoutesLayout from "./layouts/PrivateRoutesLayout";
import SignUp from "./pages/SignUp/SignUp";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import DashboardAddAutomation from "./pages/DashboardAddAutomation/DashboardAddAutomation";
import Feedback from "./pages/DashboardAddAutomation/Feedback";
import EditAutomation from "./pages/EditAutomation/EditAutomation";
import Settings from "./pages/Settings/Settings";
import SignUpVerifyAccount from "./pages/SignUp/SignUpVerifyAccount/SignUpVerifyAccount";
import SignUpUnableVerify from "./pages/SignUp/SignUpUnableVerify/SignUpUnableVerify";
import RecoveryPasswordEmail from "./pages/ForgotPassword/RecoveryPasswordEmail/RecoveryPasswordEmail";
import ConfirmNewPassword from "./pages/ForgotPassword/ConfirmNewPassword/ConfirmNewPassword";
import Verify from "./pages/SignUp/SignUpVerifyEmail/Verify";
import SignUpVerifyAccountForm from "./pages/SignUp/SignUpVerifyAccount/SignUpVerifyAccountForm";
import NotFound from "./pages/NotFound";
import TransfersList from "./pages/TransfersList/TransfersList";
import { ThemeProvider } from "@mui/material";
import Landing from "./pages/Landing/Landing";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import AuthRouter from "./elements/AuthRouter/AuthRouter";
import { getAuth, signOut } from "firebase/auth";
import { reset } from "./redux/sliceAuth";
import { useDispatch, useSelector } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { v4 as uuidv4 } from "uuid";
import { SharedLayout } from "./layouts/SharedLayout";
import { resetUserAutomation } from "./redux/sliceUserAutomation";
import { setSessionId } from "./redux/sliceAppState";

import { useMixpanel } from "./helpers/mixpanel";
import { useOpenTabsCounter } from "./helpers/openTabsCounter";
import { ABANDONED_ONBOARDING_DATA } from "./helpers/utils";
import { useSendEmailInfoMutation } from "./api/apiAuth";
import moment from "moment/moment";
import Faq from "./elements/Faq/Faq";
import Blog from "./pages/Blog/Blog";
import BlogPost from "./pages/Blog/BlogPost";
import { RootState } from "./redux/store";
import { UserStatuses } from "./helpers/enumStatuses";
import { darkTheme } from "./themes";

function App() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const mixpanel = useMixpanel();
  const envLogoutTimer = process.env.REACT_APP_LOGOUT_IDLE_TIMER;
  const tenMinutesTimer = 10 * 60 * 1000;
  const LOGOUT_IDLE_TIMER = envLogoutTimer ? +envLogoutTimer : tenMinutesTimer;
  const [sendEmailInfo, sendEmailInfoResult] = useSendEmailInfoMutation();
  const userData: any = useSelector<RootState>(
    (state) => state.userNotifications,
  );

  const autoLogout = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      return;
    }
    const idToken = await user.getIdToken();
    const hiddenFieldValue =
      (document.getElementById("abandonedOnboardingStage") as HTMLInputElement)
        ?.value || null;
    const abandonedObjectData = hiddenFieldValue
      ? ABANDONED_ONBOARDING_DATA[hiddenFieldValue]
      : {};
    if (userData?.userData.status === UserStatuses.INCOMPLETE) {
      await sendEmailInfo({
        idToken,
        body: {
          event: "USER_ABANDONED_ONBOARDING",
          data: {
            name: user.displayName || "",
            email: user.email || "",
            lastPageViewed: abandonedObjectData?.title || "",
            registrationDate: moment(),
            abandonedStage: abandonedObjectData?.stage || "",
          },
        },
      });
    }

    signOut(auth)
      .then(() => {
        mixpanel("Logged_out");
        dispatch(reset());
        dispatch(resetUserAutomation());
        navigation("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onIdle = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      autoLogout();
    }
  };

  //set current session ID to redux
  useEffect(() => {
    const sessionId = uuidv4();
    dispatch(setSessionId(sessionId));
  }, []);

  const [getTabsOpenCount] = useOpenTabsCounter();
  const logoutIfNotLastTab = () => {
    const tabsOpen = getTabsOpenCount();
    if (typeof tabsOpen !== "number" || tabsOpen > 1) {
      return;
    }
    autoLogout();
  };

  useEffect(() => {
    window.addEventListener("pagehide", logoutIfNotLastTab);
    return () => {
      window.removeEventListener("pagehide", logoutIfNotLastTab);
    };
  }, []);

  useIdleTimer({
    timeout: LOGOUT_IDLE_TIMER,
    onIdle: onIdle,
    crossTab: true,
  });

  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
        <Toaster
          toastOptions={{
            // duration: process.env.REACT_APP_TOAST_DURATION as number | undefined,
            duration: 6000,
          }}
        />
        <AuthProvider>
          <Routes>
            <Route element={<SharedLayout />}>
              <Route
                path="/login"
                element={
                  <AuthRouter>
                    <Login />
                  </AuthRouter>
                }
              />
              <Route
                path="/recovery-password"
                element={<RecoveryPasswordEmail />}
              />
              <Route
                path="/confirm-password"
                element={<ConfirmNewPassword />}
              />
              <Route path="/verify" element={<Verify />} />
              <Route path="/verify-account" element={<SignUpVerifyAccount />} />
              <Route path="/signup" element={<SignUp />} />
              <Route element={<PrivateRoutesLayout />}>
                <Route
                  path="/add-automation"
                  element={<DashboardAddAutomation />}
                />
                <Route path="/feedback" element={<Feedback />} />
                <Route
                  path="/automation-edit/:automationId"
                  element={<EditAutomation />}
                />
                <Route path="/settings" element={<Settings />} />
                <Route path="/automations" element={<Dashboard />} />
                <Route path="/transfers" element={<TransfersList />} />
                <Route
                  path="/signup-retry-verify"
                  element={<SignUpVerifyAccountForm />}
                />
                <Route
                  path="/signup-unable-verify"
                  element={<SignUpUnableVerify />}
                />
              </Route>
              <Route path="/" element={<Landing />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/terms-of-service" element={<TermsOfUse />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:postId" element={<BlogPost />} />
              <Route path="/faq" element={<Faq />} />
            </Route>
          </Routes>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

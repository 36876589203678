import "./TransfersTable.css";

import {
  getFormattedTransferDate,
  getStatusIcon,
  getToolTipText,
  getToolTipWidth,
  getTransferDirectionInfo,
} from "../../helpers/transfersUtils";
import { Tooltip } from "@mui/material";
import { Direction } from "../../helpers/types";

interface ITransaction {
  status: string;
  createdAt: string;
  amount: number;
  direction: Direction;
  isReflectedInPlaid: boolean;
}

interface TransferTableProps {
  transactionsData: ITransaction[];
}

const TransfersTable = ({ transactionsData }: TransferTableProps) => {
  const transactions = transactionsData.map((transaction: ITransaction) => {
    const {
      createdAt,
      direction,
      status,
      isReflectedInPlaid,
      amount: transactionAmount,
    } = transaction;

    const { from, to } = getTransferDirectionInfo(direction);

    const statusIcon = getStatusIcon(status, isReflectedInPlaid);
    const tooltipTitle = getToolTipText(status, isReflectedInPlaid);
    const tooltipWidth = getToolTipWidth(status, isReflectedInPlaid);
    const date = getFormattedTransferDate(createdAt);

    const clockIconWithTooltipComponent = (
      <Tooltip
        title={tooltipTitle}
        arrow
        enterTouchDelay={0}
        placement="bottom"
        componentsProps={{
          tooltip: {
            sx: {
              textAlign: "left",
              width: `${tooltipWidth}px`,
              color: "#FAFAFA",
            },
          },
        }}
      >
        <img src={statusIcon} className="status__icon" alt="Status Icon" />
      </Tooltip>
    );

    const amount = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(transactionAmount);

    return (
      <div className="row__table" key={createdAt}>
        <span className="row_icon">
          {clockIconWithTooltipComponent}
          {date}
        </span>
        <span>{from}</span>
        <span>{to}</span>
        <span>{amount}</span>
      </div>
    );
  });

  return (
    <>
      <div className="table__container">
        <div className="title">Latest Transfers</div>
        <div className="tableBlock">
          <div className="table__holder">
            <div className="table__data">
              <div className="header__row__table">
                <span>Date</span>
                <span>From</span>
                <span>To</span>
                <span>Amount</span>
              </div>
              {transactions}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransfersTable;

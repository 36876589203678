export enum AccountType {
  CHECKING = "checking",
  SAVINGS = "savings",
}

export type Account = {
  id: string;
  userId: string;
  accessToken: string;
  plaidAccountId: string;
  plaidItemId: string;
  dwollaFundingSourceUrl: string | null;
  availableBalance: number;
  mask: string;
  name: string;
  officialName: string;
  subtype: string;
  cacheType: string;
  type: string;
  institution: {
    id: string | null;
    name: string | null;
    logo: string | null;
  };
  interestRate: number | null;
  routingNumber: string | null;
  wireRoutingNumber: string | null;
  accountNumber: string | null;
  status: string;
  plaidCursor: string;
  hasHistoricalBalancesUpdated: boolean;
  updatedAt: string;
  createdAt: string;
};

export enum Direction {
  TO_CHEKCING = 0,
  TO_SAVINGS = 1,
}

export interface IInitiatedTransaction {
  existsOldTransaction: boolean;
  showMessage: boolean;
  amount: number;
  direction: Direction;
}

export interface IInitiatedTransactionMap {
  [key: string]: IInitiatedTransaction;
}

export interface IAutomation {
  pendingTransaction: { [field: string]: any };
  direction: Direction;
  checkingAccount: { [field: string]: any };
  checkingAccountId: string;
  savingAccountId: string;
  savingAccount: { [field: string]: any };
  fixedValue: string;
  status: any;
  id: string;
}
